import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics";

const AppComingSoonSection1Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  margin-bottom: 30px;
  @media(max-width:1199px){
    margin-bottom: 0px;
  }
`

const AppComingSoonSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-top:61px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`

export default function AppComingSoonSection1(props) {
  return (
  <AppComingSoonSection1Background backgroundColor={props.Background} color={props.TextColor}>
      <img alt="WomenWorks - app" src="/page-app-coming-soon-img1.jpg" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:145px;
            height: 500px;
            max-width: 36vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="8" >
                <AppComingSoonSection1Title>
                    Find the best growth partner for your career
                </AppComingSoonSection1Title>
              </Col>
              <Col xs="12" lg="4">
                <img alt="WomenWorks - app" src="/page-app-coming-soon-img1.jpg" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      width: 516px;
                      height: 500px;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="8">
                <p css={css`margin-bottom:24px;min-height:54px;max-height:216px;`}>
                    Access a network you most likely wouldn’t have accessed before. Don’t worry - we’ll do the matching!
                </p>
                <Row >
                  <Col xs="auto" css={css`padding-right:0;`}>
                    <FAHeart icon={faHeart} />
                  </Col>
                  <Col css={css`padding-left:7px;margin-bottom:20px;`}>
                    <b>Enjoy a 20% off for mentorship programs registered through the app!</b>
                  </Col>
                </Row>
                <Row >
                  <Col xs="auto" css={css`padding-right:0;`}>
                    <FAHeart icon={faHeart} />
                  </Col>
                  <Col css={css`padding-left:7px;margin-bottom:20px;`}>
                    Fill in your goals and get introduced for a 1:1 chat with relevant people for your personal and professional growth.
                  </Col>
                </Row>
                <Row >
                  <Col xs="auto" css={css`padding-right:0;`}>
                    <FAHeart icon={faHeart} />
                  </Col>
                  <Col css={css`padding-left:7px;margin-bottom:20px;`}>
                    Got something to share? Apply to become a mentor and earn income when you share connections and or/ knowledge.
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row css={css`
              @media(max-width:991px){
                display:flex;
                justify-content: center;
                }`}>
              <Col xs="auto" lg="2" md="3" css={css`
              @media(max-width:1199px){
                margin-right:20px;
                }
              @media(max-width:768px){
                display:flex;justify-content:center;margin-right:0px;
                }
              `}>
              <OutboundLink href="https://apps.apple.com/us/app/womenworks/id1538858709" target="_blank" rel="noreferrer">
                <img alt="WomenWorks - WomenWorks App Questionnaire" src="/appstore-badge.png" 
                  css={css`
                    width:178px;
                    height:57px;
                    @media(max-width:768px){
                      margin-bottom: 20px;
                    }
                  `}
                />
                </OutboundLink>
              </Col>
              <Col xs="auto" lg="2" md="3">
                <OutboundLink href="https://play.google.com/store/apps/details?id=io.womenworks.app" target="_blank" rel="noreferrer" css={css`@media(max-width:768px){display:flex;justify-content:center;}`}>
                  <img alt="WomenWorks - WomenWorks App Questionnaire" src="/google-play-badge.png" css={css`width:178px;height:57px;`}/>
                </OutboundLink>
              </Col>
              <Col lg="8" md="6" css={css`
              margin-top:36px;
              @media(max-width:991px){
                display:none;
              }
              `}></Col>
            </Row>
          </SectionItem>
      </Container>
  </AppComingSoonSection1Background>
  )
}

